import { ref, watch, computed } from '@vue/composition-api'
import digitalRecordService from '@/services/digitalrecord.service'
import useCommon from '@/libs/common'
import i18n from '@/libs/i18n'
import { downloadFile, Origen } from '@/libs/downloadHelper'
import { v4 as uuidv4 } from 'uuid'
import store from '@/store'

export default function useDigitalRecordQuery() {
  const isSausage = ref(true)

  const common = useCommon()

  const { fetchDownloadAllDigitalRecords, fetchOperations } = digitalRecordService()

  const tableColumns = [
    {
      label: i18n.t('AppDigitalRecord.list.customer'),
      key: 'cliente',
      sortKey: 'ClienteRazonSocial',
      sortable: true,
    },
    { label: i18n.t('AppDigitalRecord.list.custom'), key: 'aduana', sortable: true },
    { label: i18n.t('AppDigitalRecord.list.petition'), key: 'numeroPedimento', sortable: true },
    { label: i18n.t('AppDigitalRecord.list.reference'), key: 'numeroReferencia', sortable: true },
    { label: i18n.t('AppDigitalRecord.list.paymentDate'), key: 'fechaPago', sortable: true },
    { label: i18n.t('AppDigitalRecord.list.documentKey'), key: 'claveDocumento', sortable: true },
    {
      label: i18n.t('AppDigitalRecord.list.operationType'),
      key: 'tipoOperacionClave',
      sortKey: 'TipoOperacion',
      sortable: true,
    },
    {
      label: i18n.t('Lists.Actions'),
      key: 'actions',
      sortKey: 'IdExpedienteDigital',
      sortable: true,
    },
  ]

  const refDigitalRecordListTable = ref(null)
  const refModalRequest = ref(null)

  // Table Handlers
  const perPage = ref(10)
  const totalDigitalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchParams = ref({
    paymetDateFrom: null,
    paymentDateTo: null,
    documentType: '',
    custom: '',
    operationType: null,
    referenceNumber: '',
    pedimentoNumber: '',
    bulk: '',
    fileTypes: [],
    customers: [],
  })
  const sortBy = ref('IdExpedienteDigital')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refDigitalRecordListTable.value ? refDigitalRecordListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDigitalRecords.value,
    }
  })
  const refetchData = () => {
    refDigitalRecordListTable.value.refresh()
  }

  const sortDigitalRecordIdAsc = () => {
    isSortDirDesc.value = false
    sortBy.value = 'operacionId'
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchDigitalRecords = (ctx, callback) => {
    fetchOperations({
      fechaPagoDesde: searchParams.value.paymetDateFrom,
      fechaPagoHasta: searchParams.value.paymentDateTo,
      tipoOperacion: searchParams.value.operationType,
      numeroReferencia: searchParams.value.referenceNumber,
      numeroPedimento: searchParams.value.pedimentoNumber,
      consultaMasiva: searchParams.value.bulk,
      claveDocumento: searchParams.value.documentType,
      aduana: searchParams.value.custom,
      tiposArchivo: searchParams.value.fileTypes,
      clientes: searchParams.value.customers,
      limit: perPage.value,
      offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
      sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
    },
    isSausage.value,
    data => {
      const { operaciones, total } = data

      operaciones.forEach(element => {
        /* eslint no-param-reassign: ["error", { "props": false }] */
        element.downloading = false
      })

      totalDigitalRecords.value = total
      callback(operaciones)
    })
  }

  const downloadAllDigitalRecords = () => {
    const mockDownload = `${Origen.MOCK}_${uuidv4()}`
    const descripcion = `${common.formatDate(new Date())}`

    downloadFile({
      id: mockDownload,
      nombreArchivo: i18n.t('AppDigitalRecord.mockDownload'),
      descripcion,
      origen: Origen.MOCK,
      mock: true,
    })
    fetchDownloadAllDigitalRecords({
      fechaPagoDesde: searchParams.value.paymetDateFrom,
      fechaPagoHasta: searchParams.value.paymentDateTo,
      claveDocumento: searchParams.value.documentType,
      aduana: searchParams.value.custom,
      tipoOperacion: searchParams.value.operationType,
      numeroReferencia: searchParams.value.referenceNumber,
      numeroPedimento: searchParams.value.pedimentoNumber,
      consultaMasiva: searchParams.value.bulk,
      tiposArchivo: searchParams.value.fileTypes,
      clientes: searchParams.value.customers,
    }, data => {
      store.commit('removeDownload', mockDownload)
      // common.toastSuccess(i18n.t('AppDigitalRecord.downloadReady'))

      const onSuccess = message => {
        common.toastSuccessWithInterpolated(message)
      }

      downloadFile({
        id: data,
        nombreArchivo: `ExpedienteDigital_${data}.zip`,
        descripcion,
        origen: Origen.ARCHIVOS,
      }, onSuccess)
    })
  }

  return {
    isSausage,
    refetchData,
    sortDigitalRecordIdAsc,
    fetchDigitalRecords,
    downloadAllDigitalRecords,
    tableColumns,
    perPage,
    currentPage,
    totalDigitalRecords,
    dataMeta,
    perPageOptions,
    searchParams,
    sortBy,
    isSortDirDesc,
    refDigitalRecordListTable,
    refModalRequest,
    downloadFile,
    Origen,
    uuidv4,
  }
}
