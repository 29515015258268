<template>
  <b-card no-body>
    <b-card-header>
      <h2>
        {{ $t('AppDigitalRecord.filters') }}
      </h2>
    </b-card-header>
    <b-card-body>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-row>
          <b-col
            v-if="customers.length > 1"
            md="4"
            class="pb-0 pt-0"
          >
            <b-form-group
              :label="$t('AppDigitalRecord.customers')"
              label-for="customers"
            >
              <v-select
                id="customers"
                v-model="searchParams.customers"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="customers"
                :reduce="option => option.id"
                multiple
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!bulkSearch"
            md="4"
            class="pt-0 pb-0"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppDigitalRecord.referenceNumber')"
              :rules="{ required: (searchParams.paymetDateFrom === '' || searchParams.paymetDateFrom === null) && (searchParams.paymentDateTo === '' || searchParams.paymentDateTo === null) && searchParams.pedimentoNumber === ''}"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.referenceNumber')"
                label-for="reference-number"
              >
                <b-form-input
                  id="reference-number"
                  v-model="searchParams.referenceNumber"
                  :placeholder="$t('AppDigitalRecord.referenceNumber')"
                  :state="getValidationState(validationContext)"
                  @input="val => {
                    if(val.length > 0){
                      searchParams.paymetDateFrom = null
                      searchParams.paymentDateTo = null
                      searchParams.operationType = 0
                    }
                  }"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="!bulkSearch"
            md="4"
            class="pt-0 pb-0"
          >
            <validation-provider
              #default="validationContext"
              name="pedimento-number"
              :rules="{ required: (searchParams.paymetDateFrom === '' || searchParams.paymetDateFrom === null) && (searchParams.paymentDateTo === '' || searchParams.paymentDateTo === null) && searchParams.referenceNumber === ''}"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.pedimentNumber')"
                label-for="pedimento-number"
              >
                <b-form-input
                  id="pedimento-number"
                  v-model="searchParams.pedimentoNumber"
                  :placeholder="$t('AppDigitalRecord.pedimentNumber')"
                  :state="getValidationState(validationContext)"
                  @input="val => {
                    if(val.length > 0){
                      searchParams.paymetDateFrom = null
                      searchParams.paymentDateTo = null
                      operationType = 0
                    }
                  }"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>

            </validation-provider>
          </b-col>
          <b-col
            v-if="!bulkSearch"
            md="4"
            class="pt-0 pb-0"
          >
            <b-form-group
              :label="$t('AppDigitalRecord.operationType')"
              label-for="operationType"
            >
              <v-select
                id="operationType"
                v-model="searchParams.operationType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="operationTypes"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!bulkSearch"
            md="4"
            class="pt-0 pb-0"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppDigitalRecord.paymentDateFrom')"
              :rules="{ required: searchParams.pedimentoNumber === '' && searchParams.referenceNumber === ''}"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.paymentDateFrom')"
                label-for="payment-date-from"
              >
                <b-form-datepicker
                  id="payment-date-from"
                  v-model="searchParams.paymetDateFrom"
                  today-button
                  reset-button
                  close-button
                  :label-today-button="$t('AppDigitalRecord.calendar.labelTodayButton')"
                  :label-reset-button="$t('AppDigitalRecord.calendar.labelResetButton')"
                  :label-close-button="$t('AppDigitalRecord.calendar.labelCloseButton')"
                  :label-prev-year="$t('AppDigitalRecord.calendar.labelPrevYear')"
                  :label-prev-month="$t('AppDigitalRecord.calendar.labelPrevMonth')"
                  :label-current-month="$t('AppDigitalRecord.calendar.labelCurrentMonth')"
                  :label-next-month="$t('AppDigitalRecord.calendar.labelNextMonth')"
                  :label-next-year="$t('AppDigitalRecord.calendar.labelNextYear')"
                  :label-no-date-selected="$t('AppDigitalRecord.calendar.labelNoDateSelected')"
                  :label-help="$t('AppDigitalRecord.calendar.labelHelp')"
                  :placeholder="$t('AppDigitalRecord.noDateSelected')"
                  :state="getValidationState(validationContext)"
                  :disabled="searchParams.referenceNumber.length > 0 || searchParams.pedimentoNumber.length > 0"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="!bulkSearch"
            md="4"
            class="pt-0 pb-0"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppDigitalRecord.paymentDateTo')"
              :rules="{ required: searchParams.pedimentoNumber === '' && searchParams.referenceNumber === ''}"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.paymentDateTo')"
                label-for="payment-date-to"
              >
                <b-form-datepicker
                  id="payment-date-to"
                  v-model="searchParams.paymentDateTo"
                  today-button
                  reset-button
                  close-button
                  :label-today-button="$t('AppDigitalRecord.calendar.labelTodayButton')"
                  :label-reset-button="$t('AppDigitalRecord.calendar.labelResetButton')"
                  :label-close-button="$t('AppDigitalRecord.calendar.labelCloseButton')"
                  :label-prev-year="$t('AppDigitalRecord.calendar.labelPrevYear')"
                  :label-prev-month="$t('AppDigitalRecord.calendar.labelPrevMonth')"
                  :label-current-month="$t('AppDigitalRecord.calendar.labelCurrentMonth')"
                  :label-next-month="$t('AppDigitalRecord.calendar.labelNextMonth')"
                  :label-next-year="$t('AppDigitalRecord.calendar.labelNextYear')"
                  :label-no-date-selected="$t('AppDigitalRecord.calendar.labelNoDateSelected')"
                  :label-help="$t('AppDigitalRecord.calendar.labelHelp')"
                  :placeholder="$t('AppDigitalRecord.noDateSelected')"
                  :state="getValidationState(validationContext)"
                  :disabled="searchParams.referenceNumber.length > 0 || searchParams.pedimentoNumber.length > 0"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="bulkSearch"
            md="12"
            class="pt-0 pb-0"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('AppDigitalRecord.bulkSearch')"
              :rules="{ required: (searchParams.paymetDateFrom === '' || searchParams.paymetDateFrom === null) && (searchParams.paymentDateTo === '' || searchParams.paymentDateTo === null) && searchParams.pedimentoNumber === ''}"
            >
              <b-form-group
                :label="$t('AppDigitalRecord.bulkSearch')"
                label-for="bulk-search"
              >
                <b-form-textarea
                  id="bulk-search"
                  v-model="searchParams.bulk"
                  :state="getValidationState(validationContext)"
                  :placeholder="$t('AppDigitalRecord.bulkSearchPlaceHolder')"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="!bulkSearch"
            md="4"
            class="pt-0 pb-0"
          >
            <b-form-group
              :label="$t('AppDigitalRecord.documentType')"
              label-for="documentType"
            >
              <v-select
                id="documentType"
                v-model="searchParams.documentType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="documentTypes.map(m => m.texto)"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!bulkSearch"
            md="4"
            class="pt-0 pb-0"
          >
            <b-form-group
              :label="$t('AppDigitalRecord.list.custom')"
              label-for="customs"
            >
              <v-select
                id="customs"
                v-model="searchParams.custom"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="texto"
                :options="customs"
                :reduce="option => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="12"
            class="pt-2"
          >
            <b-form-checkbox
              v-model="bulkSearch"
              class="custom-control-primary"
              @change="() => {
                searchParams.referenceNumber = '',
                searchParams.pedimentoNumber = '',
                searchParams.paymetDateFrom = '',
                searchParams.paymentDateTo = '',
                searchParams.operationType = 0,
                searchParams.customers = null,
                searchParams.bulk = ''
              }"
            >
              {{ $t('AppDigitalRecord.bulkSearch') }}
            </b-form-checkbox>
          </b-col>
          <b-col
            md="12"
            class="mt-2"
          >
            <b-button
              variant="primary"
              block
              @click="handleSubmit(clickQuery)"
            >
              {{ $t('AppDigitalRecord.query') }}
              <feather-icon
                icon="SearchIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BButton,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, defineComponent } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, requiredIf } from '@validations'

import optionsService from '@/services/option.service'
import digitalRecordService from '@/services/digitalrecord.service'

export default defineComponent({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BButton,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormTextarea,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    searchParams: {
      required: true,
      type: Object,
    },
  },
  setup(props, { emit }) {
    /* Services */
    const { fetchFileTypes } = digitalRecordService()
    const {
      fetchCustomers,
      fecthCustoms,
      fetchDocumentoClavesUnicos,
      fetchOperationTypes,
    } = optionsService()

    /* Data */
    const customers = ref([])
    const customs = ref([])
    const fileTypes = ref([])
    const documentTypes = ref([])
    const operationTypes = ref([])

    fetchCustomers(data => { customers.value = data })
    fecthCustoms(data => { customs.value = data })
    fetchFileTypes(data => { fileTypes.value = data })
    fetchDocumentoClavesUnicos(data => { documentTypes.value = data })
    fetchOperationTypes(data => { operationTypes.value = data })

    /* Validations */
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {})

    /* Methods */
    const clickQuery = () => {
      emit('update:search-params', props.searchParams)
      emit('refetch-data')
      emit('hide-modal')
    }

    const bulkSearch = ref(false)
    return {
      // Data
      customers,
      customs,
      fileTypes,
      documentTypes,
      operationTypes,
      // Validation
      refFormObserver,
      getValidationState,
      resetForm,
      required,
      requiredIf,
      // Events
      clickQuery,
      // UI
      bulkSearch,
    }
  },
})
</script>
<style lang="scss">

</style>
