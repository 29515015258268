<template>
  <div>
    <b-modal
      v-if="dias <= 30"
      id="modal-request"
      ref="refModalRequest"
      :title="$t('DigitalRecords')"
      ok-only
    >
      {{ $t('AppDigitalRecord.requestMessage') }}
      <!-- <br>
      <br>
      <span class="text-danger">{{ $t('AppDigitalRecord.warning') }}</span>
      <br>
      {{ $t('AppDigitalRecord.warningMessage') }} -->
      <br>
      <br>
      <b-spinner />
    </b-modal>
    <b-modal
      v-else
      id="modal-request"
      ref="refModalRequest"
      :title="$t('AppDigitalRecord.downloadError')"
      ok-only
    >
      <span class="text-danger">{{ $t('AppDigitalRecord.requestMessageError') }}</span>
    </b-modal>
    <b-modal
      ref="refModalFilter"
      size="xl"
      hide-footer
      hide-header
    >
      <digital-record-filters
        :search-params.sync="searchParams"
        @refetch-data="refetchData"
        @hide-modal="refModalFilter.hide()"
      />
    </b-modal>
    <b-card>
      <b-card-header>
        <b-row>
          <b-col
            md="1"
          >
            <b-form-group
              :label="$t('Lists.Show')"
              label-for="per-page"
            >
              <v-select
                id="per-page"
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="5"
          >
            <b-form-group
              :label="$t('AppDigitalRecord.documentFilter')"
              label-for="search-file-types"
            >
              <v-select
                id="search-file-types"
                v-model="searchParams.fileTypes"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="title"
                :options="fileTypes"
                :reduce="option => option.key"
                :multiple="true"
                @option:selecting="refetchData"
                @option:deselecting="refetchData"
              />
            </b-form-group>
          </b-col>
          <!--Actions -->
          <b-col
            align-self="center"
            class="pt-2"
            md="3"
          >
            <b-button
              variant="outline-info"
              block
              @click="refModalFilter.show()"
            >
              <feather-icon
                icon="FilterIcon"
                size="16"
                class="align-middle"
              />
              {{ $t('Components.ListHeaderFilter.Filters') }}
            </b-button>
          </b-col>
          <b-col
            align-self="center"
            class="pt-2"
            md="3"
          >
            <b-dropdown
              variant="outline-primary"
              block
              menu-class="w-100"
            >
              <template #button-content>
                <feather-icon
                  icon="CommandIcon"
                  size="16"
                  class="align-middle"
                />
                {{ $t('Components.ListHeader.Actions') }}
              </template>
              <b-dropdown-item @click="changeStateTime">
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                />
                <span class="align-middle ml-50">{{ stateTime.value ? $t('Components.List.Filters.DateWhitoutHour') : $t('Components.List.Filters.FormatDate') }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click="clickDownloadAllDigitalRecords">
                <feather-icon
                  icon="ArchiveIcon"
                />
                {{ $t('AppDigitalRecord.requestAll') }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-card-header>
      <b-row class="mb-1">
        <div
          v-for="filter in getFilters(searchParams)"
          :key="`filter-${filter.name}`"
        >
          <b-badge
            v-if="getValueFilter(filter)"
            class="m-1"
            variant="primary"
          >
            {{ getValueFilter(filter) }}
            <span
              v-if="canBeFilter(filter.name)"
              class="circle"
              @click="clearFilter(filter.name)"
            >
              <feather-icon
                size="30"
                icon="XIcon"
              />
            </span>
          </b-badge>
        </div>
      </b-row>
      <b-table
        ref="refDigitalRecordListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        :items="fetchDigitalRecords"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(fechaPago)="data">
          <b-media
            v-if="stateTime.value"
            vertical-align="center"
          >
            {{ data.item.fechaPago | formatDateTime }}
          </b-media>
          <b-media
            v-else
            vertical-align="center"
          >
            {{ data.item.fechaPago | formatDate }}
          </b-media>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <file-downloader
            :idArchivo="`${data.item.idExpedienteDigital}`"
            :nombreArchivo="`ED_NumeroReferencia_${data.item.numeroReferencia}.zip`"
            :origen="Origen.EXPEDIENTE_DIGITAL"
            :titulo="`No. Referencia: ${data.item.numeroReferencia}`"
            :descripcion="`Ped: ${data.item.aduana} ${data.item.numeroPedimento} [${data.item.tipoOperacionClave} ${data.item.claveDocumento}] ${data.item.cliente}`"
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.from }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDigitalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BModal,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BMedia,
  BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  ref,
  defineComponent,
  onUnmounted,
  computed,
} from '@vue/composition-api'
import i18n from '@/libs/i18n'
import { Origen } from '@/libs/downloadHelper'
import FileDownloader from '@/components/FileDownloader.vue'
import optionsService from '@/services/option.service'
import digitalRecordService from '@/services/digitalrecord.service'
import DigitalRecordFilters from '../DigitalRecordFilters.vue'
import useDigitalRecordQuery from '../useDigitalRecordQuery'
import digitalRecordStoreModule from '../digitalRecordStoreModule'

export default defineComponent({
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BModal,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    vSelect,
    DigitalRecordFilters,
    FileDownloader,
    Origen,
  },
  setup() {
    const {
      fetchCustomers,
      fecthCustoms,
      fetchDocumentoClavesUnicos,
      fetchOperationTypes,
    } = optionsService()
    const {
      fetchFileTypes,
    } = digitalRecordService()

    const customers = ref([])
    const customs = ref([])
    const documentTypes = ref([])
    const operationTypes = ref([])
    const fileTypes = ref([])
    const dias = ref(null)

    const refModalFilter = ref(null)

    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateQuery')) {
      dateTimeFormater.push({ key: 'formatDateQuery', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateQuery'))

    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }

    fetchCustomers(data => { customers.value = data })
    fecthCustoms(data => { customs.value = data })
    fetchDocumentoClavesUnicos(data => { documentTypes.value = data })
    fetchOperationTypes(data => { operationTypes.value = data })
    fetchFileTypes(data => { fileTypes.value = data })

    const {
      refetchData,
      sortDigitalRecordIdAsc,
      fetchDigitalRecords,
      downloadAllDigitalRecords,
      tableColumns,
      perPage,
      currentPage,
      totalDigitalRecords,
      dataMeta,
      perPageOptions,
      searchParams,
      sortBy,
      isSortDirDesc,
      refDigitalRecordListTable,

      refModalRequest,
    } = useDigitalRecordQuery()
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-digitalRecord'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, digitalRecordStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const getFilters = computed(() => conditions => Object.entries(conditions).map(([name, obj]) => ({ name, value: obj })))

    const getValueFilter = computed(() => condition => {
      if (condition.value === null || condition.value === '' || condition.value.length === 0) {
        return false
      }
      let nameCustomers = ''
      switch (condition.name) {
        case 'paymetDateFrom':
          return `${i18n.t('AppDigitalRecord.paymentDateFrom')} - ${condition.value}`
        case 'paymentDateTo':
          return `${i18n.t('AppDigitalRecord.paymentDateTo')} - ${condition.value}`
        case 'documentType':
          return `${i18n.t('AppDigitalRecord.documentType')} - ${condition.value}`
        case 'custom':
          return `${i18n.t('AppDigitalRecord.list.custom')} - ${condition.value}`
        // case 'operationType':
        //   return `${i18n.t('AppDigitalRecord.operationType')} - ${operationTypes.value.find(e => e.id === condition.value).texto}`
        case 'referenceNumber':
          return `${i18n.t('AppDigitalRecord.referenceNumber')} - ${condition.value}`
        case 'pedimentoNumber':
          return `${i18n.t('AppDigitalRecord.pedimentNumber')} - ${condition.value}`
        case 'customers':
          // eslint-disable-next-line no-restricted-syntax
          for (const idClient of condition.value) {
            nameCustomers += `${customers.value.find(e => e.id === idClient).texto}`
          }
          return `${i18n.t('AppDigitalRecord.customers')} - ${nameCustomers}`
        default:
          return false
      }
    })

    const clearFilter = name => {
      let clearableValue = ''
      switch (name) {
        case 'paymetDateFrom':
        case 'paymentDateTo':
        case 'referenceNumber':
        case 'pedimentoNumber':
        case 'custom':
          clearableValue = ''
          break
        case 'customers':
          clearableValue = []
          break
        case 'documentType':
        case 'operationType':
          clearableValue = null
          break
        default:
          break
      }
      searchParams.value[name] = clearableValue
      refetchData()
    }

    const canBeFilter = computed(() => name => {
      switch (name) {
        case 'paymetDateFrom':
        case 'paymentDateTo':
          return false
        default:
          return true
      }
    })

    return {
      refetchData,
      sortDigitalRecordIdAsc,
      fetchDigitalRecords,
      downloadAllDigitalRecords,
      changeStateTime,
      clearFilter,
      tableColumns,
      perPage,
      currentPage,
      totalDigitalRecords,
      dataMeta,
      perPageOptions,
      fileTypes,
      searchParams,
      sortBy,
      isSortDirDesc,
      refDigitalRecordListTable,
      refModalRequest,
      refModalFilter,
      stateTime,
      getFilters,
      getValueFilter,
      canBeFilter,
      dias,
      Origen,
    }
  },
  methods: {
    clickDownloadAllDigitalRecords() {
      // const fechaPagoHasta = new Date(this.searchParams.paymentDateTo)
      // const fechaPagoDesde = new Date(this.searchParams.paymetDateFrom)
      this.dias = 0 // (fechaPagoHasta - fechaPagoDesde) / (1000 * 60 * 60 * 24)
      if (this.dias <= 30) { this.downloadAllDigitalRecords() }
      this.refModalRequest.show()
    },
  },
})
</script>
<style scoped>
.circle {
  margin-left: 0.3rem;
}
.circle :hover{
  cursor: pointer;
}

</style>
